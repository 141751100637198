<template>
  <router-view></router-view>
</template>

<script>
// import { onMounted } from "vue";
// import { useStore } from "vuex";
export default {
  name: "SystemMangement",
  setup() {
    // const { dispatch } = useStore();
    // const getDepartList = () => {
    //   dispatch("departmentList");
    // };
    // const getDepartType = () => {
    //   dispatch("departmentType");
    // };
    // const getRoleList = () => {
    //   dispatch("roleList");
    // };
    // const getDepartTree = () => {
    //   dispatch("departmentTree");
    // };
    // onMounted(() => {
    //   getDepartList();
    //   getDepartType();
    //   getRoleList();
    //   getDepartTree();
    // });
  },
};
</script>

<style lang="scss" scoped>
.system-warp {
  padding: 32px 24px 20px 24px;
  .user-btn {
    display: flex;
  }
}
</style>